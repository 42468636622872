import {getAnalytics} from 'firebase/analytics'
import {initializeApp} from 'firebase/app'

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
}

export const firebase = initializeApp(firebaseConfig)

export const getFirebaseAnalytics = (aggreedToCookies: boolean) => {
  if (!aggreedToCookies || typeof window === 'undefined') {
    return undefined
  } else {
    return getAnalytics(firebase)
  }
}
